import React, { useMemo } from 'react';
import { Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import { FieldRadio, Row } from '@dex/bubl-dash';

const UsersCreateEditForm: React.FC<any> = (props: any) => {

    const { data, form } = props;

    return useMemo(() => (

        <>

            <Row gutter={8} edge={true}>

                {form.values.roles?.includes('technician') &&
                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldRelationship
                            form={form}
                            label='Service Provider'
                            name='providers_ids'
                            required={true}
                            preload={true}
                            endpoint={'Providers'}
                            defaultValue={form.defaults.providers_ids}
                            isMulti={true}
                        />

                    </Col>
                }

                {form.values.roles?.includes('advisor') &&
                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldRelationship
                            form={form}
                            label='Agency'
                            name='agency_id'
                            required={false}
                            preload={true}
                            endpoint={'Agencies'}
                            defaultValue={form.defaults.agency_id}
                        />

                    </Col>
                }

                {form.values.roles?.includes('acquirer') &&
                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldRelationship
                            form={form}
                            label='Acquirer'
                            name='acquirer_id'
                            required={true}
                            preload={true}
                            endpoint={'Acquirers'}
                            defaultValue={form.defaults.acquirer_id}
                        />

                    </Col>
                }

                {(form.values.roles?.includes('technician') || form.values.roles?.includes('advisor') || form.values.roles?.includes('acquirer')) &&
                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldRadio
                            form={form}
                            label='Company Manager'
                            name='manager'
                            defaultValue={form.defaults.manager}
                            required={true}
                            options={[
                                { label: 'Yes', value: 'yes' },
                                { label: 'No', value: 'no' },
                            ]}
                        />

                    </Col>
                }

            </Row>

        </>

    ), [data, form]);

}

export default UsersCreateEditForm;
