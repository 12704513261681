import React, { useCallback, useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import MerchantStages from '../Stages/MerchantStages';
import { ButtonRow, PanelTabs, Space, useApp } from '@dex/bubl-dash';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import MerchantBilling from './MerchantBilling';
import Button from '@dex/bubl-dash/src/lib/components/Button/Button';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import { date, titleCase } from '@dex/bubl-helpers';
import TerminalLink from '../../../elements/TerminalLink/TerminalLink';
import RouterLink from '../../../elements/RouterLink/RouterLink';
import DockingLink from '../../../elements/DockingLink/DockingLink';
import SwivelLink from '../../../elements/SwivelLink/SwivelLink';
import SimCardLink from '../../../elements/SimCardLink/SimCardLink';
import MidTidLink from '../../../elements/MidTidLink/MidTidLink';
import MerchantHistory from './MerchantHistory';
import MerchantAnnualVisitation from './MerchantAnnualVisitation';

const MerchantsSingleView: React.FC<any> = (props: any) => {

    const { data, hash } = props;

    const app = useApp();

    const handlePrint = useCallback((action) => {

        const baseUrl = app.options.endpoint.slice(0, -4);

        window.open(`${baseUrl}/merchants/${data.id}/merchant/${action}`);

    }, []);

    const [tab, setTab] = useState(data.status === "new_merchant" ? "recruitment" : "history");

    let tabs = [
        { name: "recruitment", label: "Recruitment Process" },
        { name: "annual_visitation", label: "Annual Visitation" },
        { name: "history", label: "Service History" },
        { name: "billing", label: "Billing" },
    ];

    if (!['new_merchant'].includes(data.status)) {
        tabs = [
            { name: "history", label: "Service History" },
            { name: "annual_visitation", label: "Annual Visitation" },
            { name: "billing", label: "Billing" },
            { name: "recruitment", label: "Recruitment Process" },
        ];
    }

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                col={{ xs: 24 }}
            >

                <Panel
                    heading={"Merchant Details"}
                    actions={() => (
                        <ButtonRow>
                            <Button
                                label={"Preview"}
                                type={"faded"}
                                size={'small'}
                                onClick={handlePrint.bind(null, "preview")}
                            />
                            <Button
                                label={"Print"}
                                type={"light"}
                                size={'small'}
                                onClick={handlePrint.bind(null, "print")}
                            />
                        </ButtonRow>
                    )}
                >
                    {data.status === "floating" && (

                        <>

                            <Button
                                label={"Reuse This Terminal For New Rent To Own Merchant"}
                                path={`/merchants/${data.id}/reuse`}
                                size={'small'}
                            />

                            <Line />

                        </>
                    )}

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Company Name",
                                key: "company_name",
                            },
                            {
                                label: "Trading Name",
                                key: "trading_name",
                            },
                            {
                                label: "Merchant Location",
                                key: "merchant_location",
                                format: "titleCase"
                            },

                        ].filter(Boolean)}
                        columnB={[
                            {
                                label: "Engagement Plan",
                                key: "engagement_plan",
                                format: "ModelLink"
                            },
                            {
                                label: "Acquirer",
                                key: "acquirer",
                                format: "ModelLink"
                            },

                        ].filter(Boolean)}
                        columnC={[
                            {
                                label: "Warranty Status",
                                key: "warranty_status",
                                format: "titleCase",
                                render: (value: any, row) => {
                                    return titleCase(row.warranty_status) + " - " + date(row.warranty_end);
                                }
                            },
                            {
                                label: "Active Date",
                                key: "active_date",
                                format: "date",
                                hideIfEmpty: true
                            },
                            {
                                label: "Inactive Date",
                                key: "inactive_date",
                                format: "date",
                                hideIfEmpty: true
                            },
                            {
                                label: "Retrieved Date",
                                key: "retrieved_date",
                                format: "dateDay",
                                hideIfEmpty: true
                            },
                            data.reused_date && {
                                label: "Re-Activated Date",
                                key: "reused_date",
                                format: "dateDay",
                                hideIfEmpty: true
                            },

                            {
                                label: "Floating Date",
                                key: "floating_date",
                                format: "dateDay",
                                hideIfEmpty: true
                            },
                            {
                                label: "Annual Visit Date",
                                key: "visit_date",
                                format: "dateDay",
                                hideIfEmpty: true,
                            },

                        ].filter(Boolean)}
                    />

                    {data.status === "cancelled" &&
                        <>
                            <Seperator
                                heading="Cancellation Details"
                                top="auto"
                                bottom="auto"
                            />

                            <MetaTable
                                data={data}
                                columnA={[
                                    {
                                        label: "Cancellation Reason",
                                        key: "cancel_application_reason",
                                        format: "nl2br",
                                    },

                                ]}
                                columnB={[
                                    {
                                        label: "Cancellation Attachment",
                                        key: "cancel_attachments",
                                        format: "file",
                                    },
                                ]}
                            />
                        </>
                    }

                    <Space />

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Business Registration Number (BRN)",
                                key: "brn",
                            },
                        ]}
                        columnB={[
                            {
                                label: "Tax Identification Number (TIN)",
                                key: "tin",
                            },
                        ]}
                        columnC={[
                            {
                                label: "SST No.",
                                key: "sst",
                            },
                        ]}
                    />

                    {data.installation_delayed_reason &&
                        <>

                            <Space />

                            <MetaTable
                                data={data}
                                columnA={[
                                    {
                                        label: "Installation Remark",
                                        key: "installation_delayed_reason",
                                        format: "nl2br"
                                    },
                                ]}

                            />
                        </>
                    }
                    <Seperator
                        heading="Contact Details"
                        top="auto"
                        bottom="auto"
                    />

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Contact Person",
                                key: "person",
                            },
                            {
                                label: "Email",
                                key: "email",
                            },
                            {
                                label: "Phone",
                                key: "phone",
                            },

                        ]}
                        columnB={[
                            {
                                label: "Billing Address",
                                key: "billing_address",
                                format: "address"
                            },
                        ]}
                        columnC={[
                            {
                                label: "Installation Address",
                                key: "installation_address",
                                format: "address"
                            },
                        ]}
                    />

                    <Seperator
                        heading="Device Details"
                        top="auto"
                        bottom="auto"
                    />

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Serial Number",
                                key: "terminal",
                                render: (value) => <TerminalLink data={value} />
                            },
                            {
                                label: "Power Adapter Type",
                                key: "adapter_type",
                                format: "intl",
                                prefix: "installation.adapter_type",
                                hideIfEmpty: true
                            },
                            {
                                label: "Device Type",
                                key: "device_type",
                                format: "intl",
                                prefix: "terminals.type"
                            },
                            {
                                label: "Telco Network",
                                key: "telco_network",
                                format: "intl",
                                prefix: "service.telco_network",
                                hideIfEmpty: true
                            },
                            {
                                label: "SIM Card No.",
                                key: "sim",
                                format: "titleCase",
                                hideIfEmpty: true,
                                render: (value) => <SimCardLink data={value} />
                            },

                            {
                                label: "SIM Data Plan",
                                key: "sim_data_plan",
                                hideIfEmpty: true
                            },
                            {
                                label: "Sam Chip Serial No.",
                                key: "sam_chip_serial",
                                hideIfEmpty: true
                            },
                            {
                                label: "Swivel Serial No.",
                                key: "swivel",
                                hideIfEmpty: true,
                                render: (value) => <SwivelLink data={value} />
                            },
                            {
                                label: "Router Serial No.",
                                key: "router",
                                hideIfEmpty: true,
                                render: (value) => <RouterLink data={value} />
                            },
                            {
                                label: "Docking Serial No.",
                                key: "docking",
                                hideIfEmpty: true,
                                render: (value) => <DockingLink data={value} />
                            },

                        ]}
                        columnB={[
                            {
                                label: "Standard Applications",
                                key: "standard_applications",
                            },
                            {
                                label: "Optional Applications",
                                key: "optional_applications",
                            },
                            data.midtid?.ecr?.remarks && {
                                label: "ECR Remarks",
                                key: "midtid.ecr.remarks",
                            },
                            {
                                label: "QR Applications",
                                key: "qr_applications",
                            },
                            {
                                label: "Action Type",
                                key: "action_type",
                                hideIfEmpty: true
                            },
                            {
                                label: "Batch No.",
                                key: "batch_no",
                                hideIfEmpty: true
                            }
                        ].filter(Boolean)}
                        columnC={[
                            {
                                label: "MID",
                                key: "mid",
                                render: (value) => <MidTidLink data={data} />
                            },
                            {
                                label: "TID",
                                key: "tid",
                            },
                            {
                                label: "Software Version",
                                key: "software_version",
                            },
                            {
                                label: "OS Version",
                                key: "os_version",
                            },
                        ]}
                    />

                    <Seperator
                        heading="Additional Details"
                        top="auto"
                        bottom="auto"
                    />

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Acquirer - Person In Charge",
                                key: "acquirer_person",
                            },
                            {
                                label: "Advisor",
                                key: "agent",
                                format: "ModelLink"
                            },
                            {
                                label: "Agency",
                                key: "agency",
                                format: "ModelLink"
                            },
                            {
                                label: "Notification Email:",
                                key: "notification_email",
                            },

                        ]}
                        columnB={[

                            {
                                label: "Additional Tags",
                                key: "tags",
                            },
                            {
                                label: "Additional Notes",
                                key: "notes",
                                format: "nl2br"
                            },
                        ]}
                        columnC={[

                            {
                                label: "Documents",
                                key: "documents",
                                format: "file"
                            },
                        ]}
                    />

                </Panel>

            </Col>


            <Col col={{ xs: 24 }}>

                <PanelTabs
                    activeTab={tab}
                    setTab={setTab}
                    tabs={tabs}
                />

                <Space />

                <div
                    key={hash}
                >

                    {tab === "recruitment" &&
                        <MerchantStages
                            data={data}
                        />
                    }

                    {tab === "annual_visitation" &&
                        <MerchantAnnualVisitation
                            data={data}
                        />
                    }

                    {tab === "history" &&
                        <MerchantHistory
                            data={data}
                        />
                    }

                    {tab === "billing" &&
                        <MerchantBilling
                            data={data}
                        />
                    }

                </div>

            </Col>

        </Row>

    ), [hash, tab]);

}

export default MerchantsSingleView;
