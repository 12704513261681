import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';
import FieldDate from '@dex/bubl-dash/src/lib/components/Fields/FieldDate';
import { FieldAutocomplete, FieldRadio, useApp } from '@dex/bubl-dash';

const TerminalsCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, mode } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const app = useApp();

    return useMemo(() => (

        <Panel
            heading={"Terminal Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    {mode === "edit" ?
                        (
                            <Col col={{ xs: 24, md: 16 }}>

                                <FieldText
                                    form={form}
                                    label={"Serial Number"}
                                    name='serial'
                                    required={true}
                                    placeholder={"Serial Number"}
                                    defaultValue={form.defaults.serial}
                                />

                            </Col>
                        ) :
                        (
                            <Col col={{ xs: 24, md: 16 }}>


                                <FieldTextArea
                                    form={form}
                                    label={"Serial Numbers"}
                                    name='serials'
                                    required={true}
                                    placeholder={"Serial Number (one per line)"}
                                    defaultValue={form.defaults.serials}
                                />


                            </Col>
                        )
                    }


                    <Col col={{ xs: 24, md: 16 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldAutocomplete
                                    form={form}
                                    label={"Model Number"}
                                    group={"model_number"}
                                    name='model'
                                    required={true}
                                    preload
                                    defaultValue={form.defaults.model}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldSelect
                                    form={form}
                                    label={"Brand"}
                                    name='brand'
                                    required={true}
                                    defaultValue={form.defaults.brand}
                                    options={[
                                        { value: "color2com_newpos", label: app.intl("terminals.brand.color2com_newpos") },
                                        { value: "color2com_newland", label: app.intl("terminals.brand.color2com_newland") },
                                        { value: "bbpos", label: app.intl("terminals.brand.bbpos") }
                                    ]}
                                />

                            </Col>

                        </Row>

                    </Col>

                    <Col col={{ xs: 24, md: 16 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Type"}
                                    name='type'
                                    required={false}
                                    defaultValue={form.defaults.type}
                                    options={[
                                        { value: "desktop", label: app.intl("terminals.type.desktop") },
                                        { value: "wireless", label: app.intl("terminals.type.wireless") },
                                        { value: "mpos", label: app.intl("terminals.type.mpos") },
                                        { value: "android", label: app.intl("terminals.type.android") }
                                    ]}
                                />

                            </Col>

                        </Row>

                    </Col>

                </Row>

                <Seperator
                    heading={"Delivery Details"}
                    top={"auto"}
                    bottom={"auto"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldDate
                            form={form}
                            label={"Delivery Received Date"}
                            name='delivery_date'
                            required={false}
                            defaultValue={form.defaults.delivery_date || ""}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldText
                            form={form}
                            label={"Delivery Received Number"}
                            name='delivery_number'
                            required={false}
                            defaultValue={form.defaults.delivery_number}
                        />

                    </Col>

                </Row>

                <Seperator
                    heading={"Additional Details"}
                    top={"auto"}
                    bottom={"auto"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 16 }}>

                        <FieldTextArea
                            form={form}
                            label={"Additional Notes"}
                            name='notes'
                            required={false}
                            defaultValue={form.defaults.notes}
                        />

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Save"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default TerminalsCreateEditForm;
