import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import InvoicesApi from '../../Invoices/InvoicesApi';
import Stage from '../../../elements/Stage/Stage';
import { FieldRadio, ModelLink } from '@dex/bubl-dash';

const InvoicesStagesGenerateOfficialReceipt: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = InvoicesApi.update(data.id, "stage_generate_official_receipt");

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"generate_official_receipt"}
            heading={"Generate Official Receipt"}
            editCaps={["stage_generate_official_receiptInvoices"]}
            editable={false}
            showForm={true}
            showMeta={data.generate_official_receipt === "yes"}
            showSave={form.changed}
            passed={data.generate_official_receipt === "yes"}
            columnA={[

                {
                    label: "Official Receipt",
                    key: "receipt_id",
                    render: (value, row) => (
                        <ModelLink data={{ id: value }} modelName="OfficialReceipts" />
                    ),
                }

            ]}
            columnB={[

            ]}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 16 }}>

                            <FieldRadio
                                form={form}
                                name={"generate_official_receipt"}
                                label={"Generate Official Receipt"}
                                required={true}
                                defaultValue={form.defaults.generate_official_receipt}
                                options={[
                                    { label: "Yes", value: "yes" },
                                    { label: "No", value: "no" },
                                ]}
                            />

                        </Col>

                    </Row>
                </>
            }
        />

    ), [props, form.hash, process.loading]);

}

export default InvoicesStagesGenerateOfficialReceipt;
