import IndexPage from '@dex/bubl-dash/src/lib/pages/IndexPage';
import InvoiceApi from "./ProformaInvoicesApi";
import ProformaInvoiceCreateEditForm from './Components/ProformaInvoiceCreateEditForm';

export default function (app, settings) {

    app.addRoute({
        name: settings.key + "New",
        zone: settings.zone,
        path: settings.key + "/create",
        caps: settings.createCaps,
        component: IndexPage,
        componentProps: {
            header: {
                getTitle: () => "Add New",
                getButtons: settings.indexButtons,
                getTabs: settings.indexTabs,
                getTabCounts: settings.indexTabCounts,
            },
            create: {
                form: ProformaInvoiceCreateEditForm,
                getCreate: InvoiceApi.create,
                getClone: InvoiceApi.getOne,
                defaults: {
                    management: "no",
                    rows: [],
                    instructions: "Please email payment confirmation to finance@pmadvisors.my"
                },
                successMessage: "Invoice",
                successRouteName: settings.key + "View",
                idKey: settings.idKey,
            },
        },
    });

}