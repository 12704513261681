import React from 'react';

import BublDash from '@dex/bubl-dash/src/lib/Dash';
import Media from '@dex/bubl-dash/src/lib/views/Media/Media';
import Users from '@dex/bubl-dash/src/lib/views/Users/Users';
import Defaults from '@dex/bubl-dash/src/lib/views/Defaults';
import Wrapper from '@dex/bubl-dash/src/lib/Wrapper';
import Acquirers from './views/Acquirers/Acquirers';
import Leasing from './views/Leasing/Leasing';
import Providers from './views/Providers/Providers';

import './styles/App.scss';

import FinanceBlack from '@dex/bubl-dash/src/lib/assets/Finance-Black.svg';
import FinanceWhite from '@dex/bubl-dash/src/lib/assets/Finance-White.svg';
import ShopBlack from './assets/Shop-Black.svg';
import ShopWhite from './assets/Shop-White.svg';
import StockBlack from './assets/Stock-Black.svg';
import StockWhite from './assets/Stock-White.svg';
import HelpdeskBlack from './assets/Helpdesk-Black.svg';
import HelpdeskWhite from './assets/Helpdesk-White.svg';
import OpsBlack from './assets/Ops-Black.svg';
import OpsWhite from './assets/Ops-White.svg';

import Migrations from './views/Migrations/Migrations';
import UsersMods from './views/Users/UsersMods';
import MigrationData from './views/MigrationData/MigrationData';
import Terminals from './views/Terminals/Terminals';
import Merchants from './views/Merchants/Merchants';
import EngagementPlans from './views/EngagementPlans/EngagementPlans';
import Addons from './views/Addons/Addons';
import Installations from './views/Installations/Installations';
import SettingsMods from './views/Settings/SettingsMods';
import ParamTemplates from './views/ParamTemplates/ParamTemplates';
import BankAccounts from './views/BankAccounts/BankAccounts';
import OfficialReceipts from './views/OfficialReceipts/OfficialReceipts';
import Invoices from './views/Invoices/Invoices';
import ProformaInvoices from './views/ProformaInvoices/ProformaInvoices';
import DebitNotes from './views/DebitNotes/DebitNotes';
import HelpdeskTickets from './views/HelpdeskTickets/HelpdeskTickets';
import Services from './views/Services/Services';
import MassRollouts from './views/MassRollouts/MassRollouts';
import Replacements from './views/Replacements/Replacements';
import Agencies from './views/Agencies/Agencies';
import Claims from './views/Claims/Claims';
import Inventories from './views/Inventories/Inventories';
import Dashboard from './views/Dashboard/Dashboard';
import Stock from './views/Stock/Stock';
import OpsMenuItems from './views/Ops/OpsMenuItems';
import OpsMenu from './views/Ops/OpsMenu';

const app = new BublDash({
    name: "PMA HQ",
    //icon: "assets/logo.png",
    defaultLang: "en",
    strings: {
        en: [
            require('./strings/merchants.json'),
            require('./strings/engagement_plans.json'),
            require('./strings/installation.json'),
            require('./strings/service.json'),
            require('./strings/terminals.json'),
            require('./strings/helpdesk.json'),
            require('./strings/inventories.json'),
        ]
    },
    accessRoles: [
        'admin',
        'developer',
        'super',

        'manager',
        'helpdesk',
        'executive',
        'sales_admin',
        'operation',
        'finance',
        'account_manager',

        'acquirer',
        //'leasing',
        'advisor',
        'technician',
        // 'merchant',
    ], //must be one of these roles in order to sign in
    userRoles: [
        {
            key: 'technician',
            label: 'Technician',
        },
        // {
        //     key: 'merchant',
        //     label: 'Merchant'
        // },
        {
            key: 'advisor',
            label: 'Advisor'
        },
        {
            key: 'acquirer',
            label: 'Acquirer'
        },
        // {
        //     key: 'leasing',
        //     label: 'Leasing'
        // },
        {
            key: 'manager',
            label: 'Manager',
            group: {
                key: "staff",
                label: "PMA Staff"
            }
        },
        {
            key: 'executive',
            label: 'Executive',
            group: {
                key: "staff",
                label: "PMA Staff"
            }
        },
        {
            key: 'sales_admin',
            label: 'Sales Admin',
            group: {
                key: "staff",
                label: "PMA Staff"
            }
        },
        {
            key: 'account_admin',
            label: 'Account Admin',
            group: {
                key: "staff",
                label: "PMA Staff"
            }
        },
        {
            key: 'account_manager',
            label: 'Account Manager',
            group: {
                key: "staff",
                label: "PMA Staff"
            }
        },
        {
            key: 'operation',
            label: 'Operation',
            group: {
                key: "staff",
                label: "PMA Staff"
            }
        },
        {
            key: 'helpdesk',
            label: 'Helpdesk',
            group: {
                key: "staff",
                label: "PMA Staff"
            }
        },
        {
            key: 'finance',
            label: 'Finance',
            group: {
                key: "staff",
                label: "PMA Staff"
            }
        },
        {
            key: 'developer',
            label: 'Developer',
            group: {
                key: "admin",
                label: "Admin"
            }
        },
        {
            key: 'admin',
            label: 'Admin',
            group: {
                key: "admin",
                label: "Admin"
            }
        },
        {
            key: 'super',
            label: 'Super',
            group: {
                key: "admin",
                label: "Admin"
            }
        },
    ],
    env: process.env.REACT_APP_ENV || "development", //set env by modifying the output sent by express server
});

app.addRoute({
    name: "home",
    path: "/",
    component: () => <Dashboard />,
    public: false,
    sidebar: false,
    componentProps: {},
    caps: [],
});


app.addZone({
    key: "helpdesk",
    label: "Helpdesk",
    priority: 10,
    icon: HelpdeskBlack,
    iconActive: HelpdeskWhite,
});

app.addZone({
    key: "stock",
    label: "Stock",
    priority: 20,
    icon: StockBlack,
    iconActive: StockWhite,
});

app.addZone({
    key: "merchants",
    label: "Merchants",
    priority: 15,
    icon: ShopBlack,
    iconActive: ShopWhite,
});

app.addZone({
    key: "ops",
    label: "Ops",
    priority: 16,
    icon: OpsBlack,
    iconActive: OpsWhite,
    menuComponent: OpsMenu,
});

app.addZone({
    key: "finance",
    label: "Finance",
    priority: 20,
    icon: FinanceBlack,
    iconActive: FinanceWhite,
});

app.addZone({
    key: "settings",
    label: "Settings",
    priority: 20,
    icon: FinanceBlack,
    iconActive: FinanceWhite,
});

//Add New Models Here
const extensions: any = [
    UsersMods,
    SettingsMods,
    Defaults,
    Users,
    Media,
    Acquirers,
    Leasing,
    Providers,
    Agencies,
    Migrations,
    MigrationData,
    Terminals,
    MassRollouts,
    Replacements,
    Merchants,
    EngagementPlans,
    Addons,
    Installations,
    BankAccounts,
    OfficialReceipts,
    ParamTemplates,
    Invoices,
    ProformaInvoices,
    // DebitNotes,
    HelpdeskTickets,
    Services,
    Claims,
    Inventories,
    Stock,
    OpsMenuItems,
];

extensions.map(extension => {

    extension(app);

    return null;

});

const App: React.FC = () => {

    return (
        <Wrapper>
            {app.render()}
        </Wrapper>
    )

}

export default App;