import React, { useCallback, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import ServicesApi from '../ServicesApi';
import { FieldDate, FieldRadio, FieldTextArea, FieldUpload } from '@dex/bubl-dash';
import { validateDatePassed } from '../../../functions/sharedValidation';

const ServicesStagesServiceNotes: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = ServicesApi.update(data.id, "stage_service_notes");

    const isDateDisabled = useCallback((date) => {

        return validateDatePassed(date, false);

    }, []);

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            reverse={false}
            name={"service_notes"}
            heading={"Service Notes"}
            editCaps={["stage_service_notesServices"]}
            editable={false}
            showForm={true}
            showMeta={false}
            passed={false}
            showSave={form.changed}
            columnA={[
                {
                    label: "Note Type",
                    key: "note_type",
                },

            ]}
            columnB={[
                {
                    label: "Warranty End Date",
                    key: "warranty_end",
                    format: "dateDay"
                },
            ]}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24 }}>

                            <FieldRadio
                                form={form}
                                label={"Type"}
                                name='note_type'
                                required={true}
                                defaultValue={form.defaults.note_type}
                                options={[
                                    { label: "General", value: "General" },
                                    { label: "Email Sent", value: "Email Sent" },
                                    { label: "Merchant Refused", value: "Merchant Refused" },
                                    { label: "Sim Card Deactivated", value: "Sim Card Deactivated" },
                                    { label: "Sim Card Activated", value: "Sim Card Activated" },
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 6 }}>

                            <FieldDate
                                form={form}
                                required={true}
                                name={"note_date"}
                                label={"Date"}
                                defaultValue={form.defaults.note_date || ""}
                                disabledDate={isDateDisabled}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 6 }}>

                            <FieldUpload
                                form={form}
                                label={"Attachments"}
                                name="note_attachments"
                                required={false}
                                limit={5}
                                defaultValue={form.defaults.note_attachments}
                                folder={"uploads"}
                            />

                        </Col>

                        <Col col={{ xs: 24 }}>

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldTextArea
                                form={form}
                                label={"Message"}
                                name='note_message'
                                required={true}
                                defaultValue={form.defaults.note_message}
                            />

                        </Col>

                    </Row>
                </>
            }
        />

    ), [props, form.hash, process.loading]);

}

export default ServicesStagesServiceNotes;
