import { apiGet, baseApi, baseApiReturn, options } from "@dex/bubl-fetch";

const endpoint = 'Merchants';

const MerchantsApi: baseApiReturn = baseApi(endpoint);

MerchantsApi.getHistory = (options: options) => {

    return apiGet({
        "url": endpoint + "/getHistory",
        ...options
    });

}

MerchantsApi.getBilling = (options: options) => {

    return apiGet({
        "url": endpoint + "/getBilling",
        ...options
    });



}

export default MerchantsApi;