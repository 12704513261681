import React, { useMemo, useState } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import PanelTabs from '@dex/bubl-dash/src/lib/components/PanelTabs/PanelTabs';
import TerminalStatusHistory from './TerminalStatusHistory';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';


const TerminalsSingleSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    const [tab, setTab] = useState("status_history");

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                col={{ xs: 24 }}
            >

                <Panel
                    heading={"Terminal Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Serial Number ",
                                key: "serial",
                            },
                            {
                                label: "Model Number ",
                                key: "model",
                            },
                            {
                                label: "Brand",
                                key: "brand",
                                format: "intl",
                                prefix: "terminals.brand"
                            },
                            {
                                label: "Type",
                                key: "type",
                                format: "intl",
                                prefix: "terminals.type"
                            },
                        ]}
                        columnB={[

                            {
                                label: "Delivery Received Date",
                                key: "delivery_date",
                                format: "dateDay"
                            },
                            {
                                label: "Delivery Received Number",
                                key: "delivery_number",
                            },
                            {
                                label: "Owner",
                                key: "owner",
                            },

                        ]}
                        columnC={[
                            {
                                label: "Additional Notes",
                                key: "notes",
                            },

                        ]}
                    />

                    {data.status === "in_stock" && data.in_stock === "write_off" && (
                        <>
                            <Seperator
                                heading={"Write-Off Details"}
                                top={"auto"}
                                bottom={"auto"}
                            />

                            <MetaTable
                                data={data}
                                columnA={[
                                    {
                                        label: "Write-Off Notes",
                                        key: "write_off_notes",
                                        format: "nl2br"

                                    },

                                ]}
                                columnB={[
                                    {
                                        label: "Faults",
                                        key: "parts_faulty",
                                    },
                                ]}
                                columnC={[
                                    {
                                        label: "Parts Recovered",
                                        key: "parts_recovered",
                                    },
                                ]}
                            />



                        </>
                    )}

                </Panel>

            </Col>
            {data.status === "in_stock" && data.in_stock === "loan" && (
                <Col
                    col={{ xs: 24 }}
                >

                    <Panel
                        heading={"Loan Details"}
                    >

                        <MetaTable
                            data={data}
                            columnA={[
                                {
                                    label: "Loan To ",
                                    key: "loan_to",
                                },
                                {
                                    label: "Loan Purpose ",
                                    key: "loan_purpose",
                                },
                            ]}
                            columnB={[
                                {
                                    label: "Device Notes ",
                                    key: "device_notes",
                                },
                            ]}
                        />

                    </Panel>

                </Col>
            )}






            <Col col={{ xs: 24 }}>

                <PanelTabs
                    activeTab={tab}
                    setTab={setTab}
                    tabs={[
                        { name: "status_history", label: "Status History" },
                        { name: "deployment", label: "Deployment" },
                    ]}
                />

                <Space />

                {tab === "status_history" && (

                    <TerminalStatusHistory
                        data={data}
                    />

                )}

                {tab === "deployment" && (

                    <TerminalStatusHistory
                        data={data}
                        filters={"deployed"}
                    />

                )}

            </Col>


        </Row>

    ), [data, tab]);

}

export default TerminalsSingleSingleView;
