import React, { useCallback, useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import FieldAddress from '@dex/bubl-dash/src/lib/components/Fields/FieldAddress';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import { ButtonGroup, FieldDate, FieldHidden, FieldList, FieldNumber, FieldRelationship, FieldRepeater, FieldSwitch, Link, Space, useApp, useMountEffect } from '@dex/bubl-dash';
import { dateDay, doMath, loop, parseNum } from '@dex/bubl-helpers';
import { invoiceHasSst, validateDatePassed } from '../../../functions/sharedValidation';
import moment from 'moment';
import ProformaInvoiceAutofill from './ProformaInvoiceAutofill';
import FieldAmount from '../../../fields/FieldAmount/FieldAmount';

const ProformaInvoiceCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const app = useApp();

    const isManager = app.userCan(['manageProformaInvoices']);

    const hasSst = invoiceHasSst(form.values.date_issued);


    const subtotal = useMemo(() => {

        let subtotal = 0;

        loop(form.values.rows, (row) => {

            subtotal += parseNum(row.value);

        });

        return subtotal.toFixed(2);

    }, [form.values]);

    const sst_total = useMemo(() => {

        let sstTotal = doMath(0);

        const calcSst = function (value, value_sst) {

            const baseValue = parseNum(value);
            const sstRate = parseNum(value_sst);

            if (sstRate === 0) return;

            const sstAmount = doMath(baseValue).multipliedBy(sstRate).dividedBy(100).toFixed(2);

            sstTotal = sstTotal.plus(sstAmount)

        }

        loop(form.values.rows, (row) => {

            if (row.breakdown_items && row.breakdown_items.length > 0) {

                loop(row.breakdown_items, (item) => {
                    calcSst(item.amount, item.amount_sst);
                })

            } else {
                calcSst(row.value, row.value_sst);
            }

        });

        return sstTotal.toFixed(2);

    }, [form.values, hasSst]);


    let total = useMemo(() => {

        let total = doMath(subtotal).plus(parseNum(form.values.deposit));

        if (hasSst) total = total.plus(sst_total);

        return total.toFixed(2);

    }, [subtotal, sst_total, form.values.deposit, hasSst]);

    const amount_payable = useMemo(() => {

        let amount_payable = doMath(total).plus(parseNum(form.values.adjustment_amount));

        return amount_payable.toFixed(2);

    }, [total, form.values]);

    const isDateDisabled = useCallback((date) => {

        return validateDatePassed(date, false);

    }, []);



    useMountEffect(() => {

        if (form.values.date_issued) return;

        form.handleChange({ name: "date_issued", value: dateDay(moment()), reset: true, silent: true });

    })

    const [showAutofill, setShowAutofill] = useState(false);

    return useMemo(() => (

        <Panel
            heading={"Invoice Details"}
            actions={() => (
                <ButtonGroup>
                    <Button
                        label={"Autofill Company Details"}
                        type={"faded"}
                        size={'normal'}
                        onClick={setShowAutofill.bind(null, !showAutofill)}
                    />
                </ButtonGroup>
            )}
        >

            {showAutofill &&
                <ProformaInvoiceAutofill
                    form={form}
                    onClose={setShowAutofill.bind(null, false)}
                />
            }

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <FieldHidden
                    form={form}
                    name={"merchant_id"}
                    value={form.defaults.merchant_id}
                />

                <FieldHidden
                    form={form}
                    name={"acquirer_id"}
                    value={form.defaults.acquirer_id}
                />

                <FieldHidden
                    form={form}
                    name={"leasing_id"}
                    value={form.defaults.leasing_id}
                />

                <FieldHidden
                    form={form}
                    name={"provider_id"}
                    value={form.defaults.provider_id}
                />

                <FieldHidden
                    form={form}
                    name={"agency_id"}
                    value={form.defaults.agency_id}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 16 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label={"Company Name"}
                                    name='company_name'
                                    required={true}
                                    defaultValue={form.defaults.company_name}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldText
                                    form={form}
                                    label={"E-Mail"}
                                    name='email'
                                    defaultValue={form.defaults.email}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldText
                                    form={form}
                                    label={"Phone"}
                                    name='phone'
                                    defaultValue={form.defaults.phone}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldText
                                    form={form}
                                    label={"Contact Person"}
                                    name='person'
                                    defaultValue={form.defaults.person}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldText
                                    form={form}
                                    label={"Business Registration Number (BRN)"}
                                    name='brn'
                                    required={false}
                                    defaultValue={form.defaults.brn}
                                    maxLength={12}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldText
                                    form={form}
                                    label={"Tax Identification Number (TIN)"}
                                    name='tin'
                                    required={false}
                                    defaultValue={form.defaults.tin}
                                    maxLength={12}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 24 }}>

                                <FieldText
                                    form={form}
                                    label={"SST No."}
                                    name='sst'
                                    required={false}
                                    defaultValue={form.defaults.sst}
                                    maxLength={15}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldTextArea
                                    form={form}
                                    label={"Description / Purpose"}
                                    name='description'
                                    required={true}
                                    defaultValue={form.defaults.description}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldRelationship
                                    form={form}
                                    label={"Bank Account"}
                                    name='bank_account_id'
                                    required={true}
                                    model={'BankAccounts'}
                                    preload={true}
                                    defaultValue={form.defaults.bank_account_id}
                                    order={["created.date DESC"]}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 24 }}>

                                <FieldText
                                    form={form}
                                    label={"Instructions"}
                                    name='instructions'
                                    required={false}
                                    defaultValue={form.defaults.instructions}
                                />

                            </Col>

                        </Row>

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24 }}>

                                {isManager &&

                                    <FieldRadio
                                        form={form}
                                        label={"Management Invoice"}
                                        name='management'
                                        required={true}
                                        defaultValue={form.defaults.management}
                                        options={[
                                            { label: "Yes", value: "yes" },
                                            { label: "No", value: "no" },
                                        ]}
                                    />

                                }

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldDate
                                    form={form}
                                    key={form.defaults.date_issued}
                                    name={"date_issued"}
                                    required={true}
                                    label={"Date Issued"}
                                    defaultValue={form.defaults.date_issued}
                                    disabledDate={isDateDisabled}
                                />

                            </Col>

                        </Row>

                    </Col>

                </Row>

                <Seperator
                    heading={"Billing Details"}
                    top={"auto"}
                    bottom={"auto"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 16 }}>

                        <FieldAddress
                            form={form}
                            label={"Billing Address"}
                            name='billing_address'
                            required={false}
                            defaultValue={form.defaults.billing_address}
                            allowedCountries={["Malaysia"]}
                            transform={"uppercase"}
                        />

                    </Col>

                </Row>

                <Seperator
                    heading={"Additional Details"}
                    top={"auto"}
                    bottom={"auto"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 16 }}>

                        <FieldTextArea
                            form={form}
                            label={"Additional Notes"}
                            name='notes'
                            required={false}
                            defaultValue={form.defaults.notes}
                        />

                    </Col>

                </Row>

                <Seperator
                    heading={"Line Items"}
                    top={"auto"}
                    bottom={"auto"}
                />

                <Row gutter={16} edge={true} justify='end'>

                    <Col col={{ xs: 24, md: 16 }}>

                        <FieldRepeater
                            form={form}
                            name={'rows'}
                            required={true}
                            addButtonLabel={"Add Line Item"}
                            defaultValues={{ quantity: 1 }}
                        >

                            {form.values.rows.map((row, x) => (

                                <React.Fragment key={form.key + "-" + x}>

                                    <Row gutter={8} edge={true}>

                                        <Col col={{ xs: 24, md: 24 }}>

                                            <FieldTextArea
                                                form={form}
                                                label={"Description"}
                                                name={`rows[${x}].description`}
                                                required={true}
                                                defaultValue={row.description}
                                                rows={1}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 4 }}>

                                            <FieldNumber
                                                form={form}
                                                label={"Quantity"}
                                                name={`rows[${x}].quantity`}
                                                required={true}
                                                defaultValue={row.quantity}
                                            />

                                        </Col>

                                        {(!form.values.rows[x].breakdown_items || form.values.rows[x].breakdown_items.length === 0) && (
                                            <Col col={{ xs: 24, md: 20 }}>

                                                <FieldAmount
                                                    form={form}
                                                    label={"Subtotal"}
                                                    name={`rows[${x}].value`}
                                                    required={true}
                                                    allowNegative
                                                    defaultValue={row.value}
                                                    defaultValueSst={row.value_sst}
                                                    fixedDecimalScale
                                                    decimalScale={2}
                                                    sst={hasSst}
                                                />

                                            </Col>
                                        )}

                                        {(form.values.rows[x].breakdown_items && form.values.rows[x].breakdown_items.length > 0) && (
                                            <Col col={{ xs: 24, md: 13 }}>
                                                <FieldNumber
                                                    form={form}
                                                    label={"Subtotal"}
                                                    name={`rows[${x}].value`}
                                                    required={true}
                                                    allowNegative
                                                    defaultValue={row.value}
                                                    fixedDecimalScale
                                                    decimalScale={2}
                                                    prefix={"RM "}
                                                />
                                            </Col>
                                        )}

                                        <Col col={{ xs: 24, md: 24 }}>

                                            <FieldList
                                                form={form}
                                                name={`rows[${x}].breakdown_items`}
                                                addButtonLabel={"Add Sub Line Item"}
                                                max={20}
                                            >

                                                {form.values.rows[x]?.breakdown_items?.map((row, y) => (

                                                    <React.Fragment key={form.key + "-" + y}>

                                                        <Row gutter={8} edge={true}>

                                                            <Col col={{ xs: 24, md: 12 }}>

                                                                <FieldText
                                                                    form={form}
                                                                    placeholder={"Description *"}
                                                                    name={`rows[${x}].breakdown_items[${y}].description`}
                                                                    required={true}
                                                                    defaultValue={row.description}
                                                                />

                                                            </Col>

                                                            <Col col={{ xs: 24, md: 12 }}>

                                                                <FieldAmount
                                                                    form={form}
                                                                    placeholder={"Amount *"}
                                                                    name={`rows[${x}].breakdown_items[${y}].amount`}
                                                                    required={true}
                                                                    allowNegative
                                                                    defaultValue={row.amount}
                                                                    defaultValueSst={row.amount_sst}
                                                                    fixedDecimalScale
                                                                    decimalScale={2}
                                                                    sst={hasSst}
                                                                />

                                                            </Col>

                                                        </Row>

                                                    </React.Fragment>

                                                ))}

                                            </FieldList>

                                        </Col>
                                    </Row>

                                </React.Fragment>

                            ))}

                        </FieldRepeater>

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <React.Fragment>
                            <FieldNumber
                                key={subtotal}
                                form={form}
                                label={"Subtotal (Excluding Tax)"}
                                name={`subtotal`}
                                allowNegative
                                defaultValue={subtotal}
                                disabled
                                prefix={"RM "}
                                fixedDecimalScale
                                decimalScale={2}
                            />
                        </React.Fragment>

                        <React.Fragment>
                            <FieldNumber
                                form={form}
                                label={"Refundable Deposit"}
                                name={`deposit`}
                                allowNegative
                                defaultValue={form.defaults.deposit}
                                prefix={"RM "}
                                fixedDecimalScale
                                decimalScale={2}
                            />
                        </React.Fragment>

                        {hasSst &&

                            <React.Fragment>
                                <FieldNumber
                                    key={sst_total}
                                    form={form}
                                    label={"SST Total"}
                                    name={`sst_total`}
                                    required={false}
                                    defaultValue={sst_total}
                                    allowNegative
                                    disabled
                                    prefix={"RM "}
                                    fixedDecimalScale
                                    decimalScale={2}
                                />
                            </React.Fragment>
                        }

                        <React.Fragment>
                            <FieldNumber
                                key={total}
                                form={form}
                                label={"Total"}
                                name={`total`}
                                required={false}
                                allowNegative
                                defaultValue={total}
                                disabled
                                prefix={"RM "}
                                fixedDecimalScale
                                decimalScale={2}
                            />
                        </React.Fragment>

                        <FieldSwitch
                            form={form}
                            label={"Adjustment"}
                            name='adjustment'
                            required={false}
                            defaultValue={form.defaults.adjustment}
                        />

                    </Col>

                </Row>
                {form.values.adjustment === true &&
                    <>
                        <Seperator
                            heading={"Adjustments"}
                            top={"auto"}
                            bottom={"auto"}
                        />

                        <Row gutter={8} edge={true}>

                            <>
                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldText
                                        form={form}
                                        label={"Adjustment Label"}
                                        name={"adjustment_label"}
                                        defaultValue={form.defaults.adjustment_label}
                                        required
                                    />
                                </Col>
                                <Col col={{ xs: 24, md: 6 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"Adjustment Amount"}
                                        name={"adjustment_amount"}
                                        defaultValue={form.defaults.adjustment_amount || 0}
                                        prefix={"RM "}
                                        required
                                        allowNegative
                                    />
                                </Col>

                                <Col col={{ xs: 24, md: 6 }}>

                                    <React.Fragment>
                                        <FieldNumber
                                            key={amount_payable}
                                            form={form}
                                            label={"Amount Payable"}
                                            name={`amount_payable`}
                                            required={false}
                                            allowNegative
                                            defaultValue={amount_payable}
                                            disabled
                                            prefix={"RM "}
                                            fixedDecimalScale
                                            decimalScale={2}
                                        />
                                    </React.Fragment>
                                </Col>

                            </>

                        </Row>
                    </>
                }

                <Line />

                <ButtonRow>
                    <Button
                        label={"Save"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>

    ), [form.hash, process.loading, total, subtotal, sst_total, showAutofill, hasSst]);


}

export default ProformaInvoiceCreateEditForm;
