import React, { useCallback, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import { Button, ButtonRow, ModelLink, Space, useApp, useForm } from '@dex/bubl-dash';
import ProformaInvoiceLineItems from './ProformaInvoiceLineItems';
import ProformaInvoicesStages from '../Stages/ProformaInvoicesStages';

const ProformaInvoiceSingleView: React.FC<any> = (props: any) => {

    const { data, hash } = props;

    const app = useApp();

    const handlePrintInvoice = useCallback((action) => {

        const baseUrl = app.options.endpoint.slice(0, -4);

        window.open(`${baseUrl}/proformainvoices/${data.id}/invoice/${action}`);

    }, []);

    const form = useForm(data);

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                col={{ xs: 24 }}
            >

                <Panel
                    heading={"Invoice Details"}
                    actions={() => (
                        <ButtonRow>
                            <Button
                                label={"Preview"}
                                type={"faded"}
                                size={'small'}
                                onClick={handlePrintInvoice.bind(null, "preview")}
                            />
                            <Button
                                label={"Print"}
                                type={"light"}
                                size={'small'}
                                onClick={handlePrintInvoice.bind(null, "print")}
                            />
                        </ButtonRow>
                    )}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Date Issued",
                                key: "date_issued",
                                format: "dateDay",
                            },
                            {
                                label: "Description",
                                key: "description",
                                format: "nl2br",
                            },
                            (data.debtor_id && data.debtor_id.includes("MA-")) && {
                                label: "Merchant",
                                key: "debtor_id",
                                render: (value, row) => (
                                    <ModelLink data={{ id: value }} modelName="Merchants" />
                                ),
                            },
                            (data.invoice_id) && {
                                key: "invoice_id",
                                label: "Invoice No.",
                                render: (value, row) => (
                                    <ModelLink data={{ id: value }} modelName="Invoices" />
                                )
                            },
                            data.receipt_id && {
                                label: "Official Receipt",
                                key: "receipt_id",
                                render: (value, row) => (
                                    <ModelLink data={{ id: value }} modelName="OfficialReceipts" />
                                ),
                            },
                        ].filter(Boolean)}
                        columnB={[
                            {
                                label: "Company Name",
                                key: "company_name",
                            },
                            {
                                label: "Contact Person",
                                key: "person",
                            },
                            {
                                label: "Phone",
                                key: "phone",
                            },
                            {
                                label: "Email",
                                key: "email",
                            },
                        ]}
                        columnC={[
                            {
                                label: "Created Date",
                                key: "created",
                                format: "OnAndBy",
                            },
                            {
                                label: "Address",
                                key: "billing_address",
                                format: "address",
                            },

                        ]}
                    />

                    <Space />

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Business Registration Number (BRN)",
                                key: "brn",
                            },
                        ]}
                        columnB={[
                            {
                                label: "Tax Identification Number (TIN)",
                                key: "tin",
                            },
                        ]}
                        columnC={[
                            {
                                label: "SST No.",
                                key: "sst",
                            },
                        ]}
                    />

                    <Space />

                    <ProformaInvoiceLineItems
                        data={data}
                    />

                </Panel>


            </Col>

            <Space />

            <Col
                col={{ xs: 24 }}
                key={hash}
            >

                <ProformaInvoicesStages
                    data={data}
                    form={form}
                />


            </Col>

        </Row >

    ), [data, form.hash]);

}

export default ProformaInvoiceSingleView;
