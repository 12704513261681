import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import { DataTable, FieldNumber, FieldRadio, FieldRange, FieldText, InputTableCell, InputTableRow, Line, MetaTable, ModelLink } from '@dex/bubl-dash';
import { date, dateDay, formatNum, loop, parseNum } from '@dex/bubl-helpers';
import { InvoiceLineItemsBreakdown } from '../../Invoices/Components/InvoiceLineItems';
import ServicesApi from '../ServicesApi';

const ServiceStagePrepareRentalInvoice: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = ServicesApi.update(data.id, "stage_prepare_rental_invoice");

    const plan = data.engagement_plan;

    const columns = [
        {
            label: "Description",
            key: "description",
        },
        {
            label: "Amount",
            key: "amount",
            alignEnd: true,
        },
        {
            label: "Agent's Commission",
            key: "commission",
            alignEnd: true,
            className: "table-bg-gray table-bdr-left-gray"
        },
    ];


    const subtotal = useMemo(() => {

        let amount = parseNum(plan?.charge_annual_subtotal || 0);

        if (form.values.adjustment_amount) {
            amount += parseNum(form.values.adjustment_amount);
        }

        return amount;

    }, [plan, form.values.adjustment_amount]);

    const commission = useMemo(() => {

        let amount = parseNum(plan?.charge_annual_agent_commission || 0) + parseNum(form.values.commission_adjustment_amount || 0);

        return amount;

    }, [plan, form.values.commission_adjustment_amount]);

    const deposit = useMemo(() => {

        let amount = parseNum(plan?.charge_annual_deposit || 0);

        if (form.values.deposit_adjustment_amount) {
            amount += parseNum(form.values.deposit_adjustment_amount);
        }

        return amount;

    }, [plan, form.values.deposit_adjustment_amount]);

    const total = useMemo(() => {

        return subtotal + deposit;

    }, [plan, subtotal, deposit]);

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"prepare_proforma_invoice"}
            heading={"Prepare Proforma Invoice"}
            editCaps={["stage_prepare_rental_invoiceServices"]}
            editable={data.prepare_proforma_invoice === "yes"} //todo: change to false after testing
            showForm={data.prepare_proforma_invoice !== "yes"}
            showMeta={data.prepare_proforma_invoice}
            showSave={form.changed}
            passed={data.prepare_proforma_invoice === "yes"}
            columnA={[
                {
                    key: "proforma_invoice_id",
                    label: "Proforma Invoice No.",
                    render: (value, row) => (
                        <ModelLink data={{ id: value }} modelName="ProformaInvoices" />
                    )
                },
                {

                    label: "Status",
                    key: "proforma_invoice.status",
                    format: "status",
                }

            ]}
            columnB={[
                {
                    label: "Total Amount",
                    key: "proforma_invoice.total",
                    format: "number",
                    prefix: "RM "
                },

            ]}
            columnC={[
                {
                    label: "Refundable Deposit Amount",
                    key: "proforma_invoice.deposit",
                    format: "number",
                    prefix: "RM "
                },
                {
                    label: "Agent's Commission",
                    key: "commission_total_amount",
                    format: "number",
                    prefix: "RM "
                },
            ]}
            fields={
                <>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldRadio
                                form={form}
                                label={"Apply Adjustment"}
                                name={"apply_adjustment"}
                                defaultValue={form.defaults.apply_adjustment}
                                options={[
                                    { label: "Yes", value: "yes" },
                                    { label: "No", value: "no" }
                                ]}
                            />

                            {form.values.apply_adjustment === "yes" &&
                                <>

                                    <FieldText
                                        form={form}
                                        label={"Adjustment Label"}
                                        name={"adjustment_label"}
                                        defaultValue={form.defaults.adjustment_label}
                                        required
                                    />

                                    <FieldNumber
                                        form={form}
                                        label={"Adjustment Amount"}
                                        name={"adjustment_amount"}
                                        defaultValue={form.defaults.adjustment_amount || 0}
                                        prefix={"RM "}
                                        required
                                        allowNegative
                                    />

                                </>
                            }

                        </Col>

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldRadio
                                form={form}
                                label={"Apply Deposit Adjustment"}
                                name={"apply_deposit_adjustment"}
                                defaultValue={form.defaults.apply_deposit_adjustment}
                                options={[
                                    { label: "Yes", value: "yes" },
                                    { label: "No", value: "no" }
                                ]}
                            />

                            {form.values.apply_deposit_adjustment === "yes" &&
                                <>

                                    <FieldText
                                        form={form}
                                        label={"Deposit Adjustment Label"}
                                        name={"deposit_adjustment_label"}
                                        defaultValue={form.defaults.deposit_adjustment_label}
                                        required
                                    />

                                    <FieldNumber
                                        form={form}
                                        label={"Deposit Adjustment"}
                                        name={"deposit_adjustment_amount"}
                                        defaultValue={form.defaults.deposit_adjustment_amount || 0}
                                        prefix={"RM "}
                                        required
                                        allowNegative
                                    />

                                </>
                            }

                        </Col>

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldRadio
                                form={form}
                                label={"Apply Commission Adjustment"}
                                name={"apply_commission_adjustment"}
                                defaultValue={form.defaults.apply_commission_adjustment}
                                options={[
                                    { label: "Yes", value: "yes" },
                                    { label: "No", value: "no" }
                                ]}
                            />

                            {form.values.apply_commission_adjustment === "yes" &&
                                <>

                                    <FieldText
                                        form={form}
                                        label={"Commission Adjustment Label"}
                                        name={"commission_adjustment_label"}
                                        defaultValue={form.defaults.adjustment_label}
                                        required
                                    />


                                    <FieldNumber
                                        form={form}
                                        label={"Commission Adjustment Amount"}
                                        name={"commission_adjustment_amount"}
                                        defaultValue={form.defaults.commission_adjustment_amount || 0}
                                        prefix={"RM "}
                                        required
                                        allowNegative
                                    />

                                </>
                            }

                        </Col>

                        <Col col={{ xs: 24 }}>

                            <DataTable
                                data={null}
                                columns={columns}
                                className={"mini-table invoice-table"}
                            >

                                <InputTableRow>

                                    <InputTableCell style={{ width: "55%" }}>


                                        {(plan?.billing_name ?? plan?.plan_name)}

                                        <InvoiceLineItemsBreakdown
                                            items={[
                                                plan?.charge_annual_maintenance_fee ? {
                                                    description: "Yearly Rental (" + date(data.warranty_start) + " - " + date(data.warranty_end) + ")",
                                                    amount: plan.charge_annual_maintenance_fee
                                                } : null,
                                            ].filter(Boolean)}
                                        />

                                    </InputTableCell>

                                    <InputTableCell style={{ width: "20%" }} alignEnd>

                                        {formatNum(plan?.charge_annual_subtotal, 2, "RM ")}

                                    </InputTableCell>

                                    <InputTableCell style={{ width: "20%" }} alignEnd className="table-bg-gray table-bdr-left-gray">

                                        {formatNum(plan?.charge_annual_agent_commission, 2, "RM ")}

                                    </InputTableCell>

                                </InputTableRow>

                                {form.values.apply_adjustment === "yes" &&
                                    <InputTableRow>

                                        <InputTableCell>
                                            Adjustment: {form.values.adjustment_label}
                                        </InputTableCell>

                                        <InputTableCell alignEnd>
                                            {formatNum(form.values.adjustment_amount, 2, "RM ")}
                                        </InputTableCell>

                                        <InputTableCell alignEnd className="table-bg-gray table-bdr-left-gray">
                                        </InputTableCell>

                                    </InputTableRow>
                                }

                                {form.values.apply_commission_adjustment === "yes" &&
                                    <InputTableRow>

                                        <InputTableCell>
                                            Commission Adjustment: {form.values.commission_adjustment_label}
                                        </InputTableCell>

                                        <InputTableCell alignEnd>
                                        </InputTableCell>

                                        <InputTableCell alignEnd className="table-bg-gray table-bdr-left-gray">
                                            {formatNum(form.values.commission_adjustment_amount, 2, "RM ")}
                                        </InputTableCell>

                                    </InputTableRow>
                                }

                                <InputTableRow>

                                    <InputTableCell alignEnd className="invoice-line-label">
                                        Subtotal (Excluding Tax)
                                    </InputTableCell>

                                    <InputTableCell alignEnd>
                                        {formatNum(subtotal, 2, "RM ")}
                                    </InputTableCell>

                                    <InputTableCell alignEnd className="table-bg-gray table-bdr-left-gray">
                                    </InputTableCell>

                                </InputTableRow>

                                <InputTableRow>

                                    <InputTableCell alignEnd className="invoice-line-label">
                                        Refundable Deposit
                                    </InputTableCell>

                                    <InputTableCell alignEnd>
                                        {formatNum(deposit, 2, "RM ")}
                                    </InputTableCell>

                                    <InputTableCell alignEnd className="table-bg-gray table-bdr-left-gray">
                                    </InputTableCell>

                                </InputTableRow>

                                <InputTableRow>

                                    <InputTableCell alignEnd className="invoice-line-label">
                                        <strong>Total</strong>
                                    </InputTableCell>

                                    <InputTableCell alignEnd>
                                        <strong>{formatNum(total, 2, "RM ")}</strong>
                                    </InputTableCell>

                                    <InputTableCell alignEnd className="table-bg-gray table-bdr-left-gray">
                                        <strong>{formatNum(commission, 2, "RM ")}</strong>
                                    </InputTableCell>

                                </InputTableRow>

                            </DataTable>

                        </Col>

                    </Row>

                </>
            }
        />

    ), [props, form.hash, process.loading]);

}

export default ServiceStagePrepareRentalInvoice;
