import React, { useEffect, useMemo, useState } from 'react';
import { Panel, Form, Row, Col, FieldRelationship, FieldRadio, Line, ButtonRow, Button, useApp, useMountEffect, FieldText, FieldAddress, FieldTextArea, Heading, Seperator, FieldUpload } from '@dex/bubl-dash';
import FieldHidden from '@dex/bubl-dash/src/lib/components/Fields/FieldHidden';
import MerchantsApi from '../../Merchants/MerchantsApi';
import { clone } from '@dex/bubl-helpers';

const ServicesCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, mode } = props;

    const [tab, setTab] = useState('merchant');

    const app = useApp();

    const [nav] = app.store('nav');

    const merchant = MerchantsApi.getOne(":id");

    useEffect(() => {

        if (mode === "edit") return;
        if (!form.values.merchant_id) return;

        merchant.run({
            url: MerchantsApi.endpoint + "/" + form.values.merchant_id,
            onComplete: (response) => {

                const _values = clone(form.values);

                _values.company_name = response.company_name;
                _values.person = response.person;
                _values.phone = response.phone;
                _values.email = response.email;
                _values.installation_address = response.installation_address;

                form.reset(_values);

            }
        })

    }, [form.values.merchant_id]);

    useMountEffect(() => {

        const params = nav.currentRoute.state.params;

        if (!params.ticket_id) return;

        form.handleChange({ silent: true, reset: true, name: "ticket_id", value: params.ticket_id });
        form.handleChange({ silent: true, reset: true, name: "merchant_id", value: params.merchant_id });
        form.handleChange({ silent: true, reset: true, name: "person", value: params.person });
        form.handleChange({ silent: true, reset: true, name: "phone", value: params.phone });
        form.handleChange({ silent: true, reset: true, name: "roll_size", value: params.roll_size });
        form.handleChange({ silent: true, reset: true, name: "type", value: "fault_call" });

    })

    // eslint-disable-next-line
    let data = props.data || {};

    let filter: any = { status: "active" };

    if (form.values.type === "amendment") filter.status = { inq: ["active", "inactive", "floating"] }

    return useMemo(() => (

        <Panel
            heading={"Service Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                {mode !== "edit" && form.values.ticket_id &&
                    <>
                        <FieldHidden
                            form={form}
                            name={"ticket_id"}
                            value={form.values.ticket_id}
                        />
                        <FieldHidden
                            form={form}
                            name={"roll_size"}
                            value={form.values.roll_size}
                        />
                    </>
                }

                {mode !== "edit" &&
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldRadio
                                button
                                form={form}
                                label={"Service Type"}
                                name='type'
                                required={true}
                                defaultValue={form.defaults.type}
                                options={[
                                    { value: "fault_call", label: app.intl("service.type.fault_call") },
                                    { value: "retrieval", label: app.intl("service.type.retrieval") },
                                    { value: "paper_roll", label: app.intl("service.type.paper_roll") },
                                    { value: "programming", label: app.intl("service.type.programming") },
                                    { value: "training", label: app.intl("service.type.training") },
                                    { value: "yearly_rental", label: app.intl("service.type.yearly_rental") },
                                    { value: "amendment", label: app.intl("service.type.amendment") },
                                    { value: "annual_visitation", label: app.intl("service.type.annual_visitation") },
                                    { value: "swap_plan", label: app.intl("service.type.swap_plan") },
                                ]}
                            />

                        </Col>

                        {form.values.type &&
                            <>
                                <Col col={{ xs: 24, md: 14 }}>

                                    <FieldRelationship
                                        key={form.values.ticket_id + form.values.type}
                                        form={form}
                                        label={"Merchant (Company Name | Status |Serial Number | TID | ID)"}
                                        name='merchant_id'
                                        required={true}
                                        model={'Merchants'}
                                        preload={true}
                                        where={filter}
                                        defaultValue={form.defaults.merchant_id}
                                    />

                                </Col>
                            </>
                        }



                    </Row>
                }
                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 10 }}>

                        <FieldTextArea
                            form={form}
                            label={"Service Notes"}
                            name='service_notes'
                            required={false}
                            defaultValue={form.defaults.service_notes}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>
                        <FieldUpload
                            form={form}
                            label={"Service Documents"}
                            name="service_document"
                            defaultValue={form.defaults.service_document}
                            folder={"uploads"}
                            limit={3}
                        />
                    </Col>

                </Row>


                {(mode === "edit" || merchant.data) && form.values.merchant_id &&
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24 }}>
                            <Seperator
                                top={"auto"}
                                bottom={"auto"}
                                heading={"Current Merchant Details"}
                            />
                        </Col>

                        <Col col={{ xs: 24, md: 16 }}>

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldText
                                        form={form}
                                        label={"Company Name"}
                                        name='company_name'
                                        required={true}
                                        defaultValue={form.defaults.company_name}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 12 }}>
                                    <FieldText
                                        form={form}
                                        label={"Contact Person"}
                                        name='person'
                                        required={false}
                                        defaultValue={form.defaults.person}
                                    />
                                </Col>

                                <Col col={{ xs: 24, md: 12 }}>
                                    <FieldText
                                        form={form}
                                        label={"E-Mail"}
                                        name='email'
                                        required={false}
                                        defaultValue={form.defaults.email}
                                    />
                                </Col>

                                <Col col={{ xs: 24, md: 12 }}>
                                    <FieldText
                                        form={form}
                                        label={"Phone"}
                                        name='phone'
                                        required={false}
                                        defaultValue={form.defaults.phone}
                                    />
                                </Col>

                                {/* <Col col={{ xs: 24 }}>

                                    <FieldTextArea
                                        form={form}
                                        label={"Additional Notes"}
                                        name='notes'
                                        required={false}
                                        defaultValue={form.defaults.notes}
                                    />

                                </Col> */}

                                <Col col={{ xs: 24 }}>

                                    <FieldAddress
                                        form={form}
                                        label={"Installation Address"}
                                        name={"installation_address"}
                                        defaultValue={form.defaults.installation_address}
                                        required
                                        allowedCountries={["Malaysia"]}
                                        transform={"uppercase"}
                                        maxLength={{
                                            line1: 120,
                                            line2: 120,
                                            city: 13,
                                            zipcode: 5,
                                        }}
                                    />

                                </Col>


                            </Row>

                        </Col>
                    </Row>
                }

                <Line />

                {form.values.company_name && form.values.merchant_id &&
                    <ButtonRow>
                        <Button
                            label={"Save"}
                            loading={process.loading}
                        />
                        <Button
                            label={"Reset"}
                            type={"faded"}
                            onClick={form.reset}
                        />
                    </ButtonRow>
                }

            </Form>

        </Panel >

    ), [form.hash, tab, process.loading, merchant.loading]);

}

export default ServicesCreateEditForm;
