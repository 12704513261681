import OfficialReceiptsIndex from './OfficialReceiptsIndex';
import OfficialReceiptsCreate from './OfficialReceiptsCreate';
import OfficialReceiptsSettings from './OfficialReceiptsSettings';
import OfficialReceiptsSingle from './OfficialReceiptsSingle';

export default function (app) {

    const settings = OfficialReceiptsSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        // addPath: settings.path + "/create",
        addCaps: settings.createCaps,
        priority: 11,
        items: [
            {
                label: "All Official Receipts",
                path: settings.path,
                caps: settings.indexCaps,
            },
        ],
    });

    OfficialReceiptsIndex(app, settings);

    OfficialReceiptsCreate(app, settings);

    OfficialReceiptsSingle(app, settings);

}