import React, { useMemo } from 'react';
import OfficialReceiptsStageRefund from './OfficialReceiptsStageRefund';
import InvoicesStagesPayment from '../../Invoices/Stages/InvoicesStagesPayment';


const OfficialReceiptsStages: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <>

            {(data.invoice || data.proforma) &&
                <InvoicesStagesPayment
                    data={data.invoice || data.proforma}
                    isReceipt={true}
                />
            }

            <OfficialReceiptsStageRefund
                data={data}
            />
        </>

    ), [data]);

}

export default OfficialReceiptsStages;
