import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';
import React from 'react';
import { Col, FieldNumber, Row } from '@dex/bubl-dash';

const FieldAmount: React.FC<FieldAmountProps> = (props) => {

    const { sst, ...rest } = props;

    return (

        <Row gutter={8} edge={true}>

            <Col col={{ xs: 18, md: 16 }}>

                <FieldNumber
                    prefix={"RM "}
                    {...rest}
                />

            </Col>

            {sst &&
                <Col col={{ xs: 6, md: 8 }}>

                    <FieldSelect
                        form={rest.form}
                        label={rest.label ? "SST %" : ""}
                        name={rest.name + "_sst"}
                        defaultValue={rest.defaultValueSst || rest.form.defaults[rest.name + "_sst"]}
                        placeholder={rest.label ? "0%" : "SST %"}
                        isClearable={false}
                        options={[
                            { label: "0%", value: "0%" },
                            { label: "6%", value: "6%" },
                            { label: "8%", value: "8%" },
                        ]}
                    />

                </Col>
            }

        </Row>

    )

}

interface FieldAmountProps {
    [key: string]: any,
}

export default FieldAmount;