import React, { useMemo } from 'react';
import { Col, DataTable, DataTableCell, InputTableRow, Line, ModelLink, Row, Space, useApp } from '@dex/bubl-dash';
import { formatNum, nl2br } from '@dex/bubl-helpers';

const OfficialReceiptsLineItems: React.FC<any> = (props: any) => {

    const { data } = props;

    const app = useApp();

    const columns = [
        {
            label: "Description",
            key: "description",
        },
        {
            label: "Quantity",
            key: "quantity",
            alignMiddle: true,
        },
        {
            label: "Subtotal",
            key: "value",
            alignEnd: true,
        },
    ].filter(Boolean);

    return useMemo(() => (

        <>

            <DataTable
                data={null}
                columns={columns}
                className={"mini-table invoice-table"}
            >


                <InputTableRow className={"invoice-line-item"} >

                    <DataTableCell style={{ width: "60%" }} className={"invoice-line-desc"}>
                        Refundable Deposit
                    </DataTableCell>

                    <DataTableCell alignMiddle className={"invoice-line-quantity"}>
                        1
                    </DataTableCell>

                    <DataTableCell alignEnd className={"invoice-line-value"}>
                        {formatNum(data.deposit, 2, "")}
                    </DataTableCell>

                </InputTableRow>

                <InputTableRow>

                    <DataTableCell colSpan={2} alignEnd className="invoice-line-label">
                        <strong>Total</strong>
                    </DataTableCell>

                    <DataTableCell alignEnd>
                        <strong>{formatNum(data.deposit, 2, "RM ")}</strong>
                    </DataTableCell>

                </InputTableRow>

            </DataTable>

        </>

    ), [data]);

}


export default OfficialReceiptsLineItems;