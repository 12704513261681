import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import OfficialReceiptsApi from '../OfficialReceiptsApi';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import { FieldDate, FieldNumber, FieldText, FieldTextArea, FieldUpload } from '@dex/bubl-dash';

const OfficialReceiptsStageRefund: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm({ refunded_amount: data.deposit, ...data });


    const process = OfficialReceiptsApi.update(data.id, "stage_refund");

    const reverse = OfficialReceiptsApi.update(data.id, "stage_reverse");

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"refund"}
            heading={"Refund"}
            editCaps={["stage_refundOfficialReceipts"]}
            editable={false}
            showForm={data.refund !== "yes"}
            showMeta={data.refund === "yes"}
            passed={data.refund === "yes"}
            showSave={form.changed}
            reverse={false}
            last_stage={"last_stage"}
            columnA={[
                {
                    label: "Refunded Date",
                    key: "refunded_date",
                    format: "date",
                },


            ].filter(Boolean)}
            columnB={[
                {
                    label: "Refunded Amount",
                    key: "refunded_amount",
                    format: "number",
                    prefix: "RM "
                },
                {
                    label: "Reference ID",
                    key: "reference_id",
                },
            ].filter(Boolean)}
            columnC={[

                {
                    label: "Refunded Slip",
                    key: "refunded_slip",
                    format: "file"
                },
            ].filter(Boolean)}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 16 }}>

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 16 }}>

                                    <FieldRadio
                                        form={form}
                                        label={"Refund"}
                                        name='refund'
                                        required={true}
                                        defaultValue={form.defaults.refund}
                                        options={[
                                            { label: "Yes", value: "yes" },
                                            { label: "No", value: "no" },
                                        ]}
                                    />

                                </Col>

                                {form.values.refund === "yes" &&
                                    <>
                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldDate
                                                form={form}
                                                label={"Refunded Date"}
                                                name='refunded_date'
                                                required={true}
                                                defaultValue={form.defaults.refunded_date}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldText
                                                form={form}
                                                label={"Referrence ID"}
                                                name='reference_id'
                                                required={true}
                                                defaultValue={form.defaults.reference_id}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldNumber
                                                form={form}
                                                label={"Refunded Amount"}
                                                name='refunded_amount'
                                                required={true}
                                                prefix={"RM "}
                                                value={form.values.refunded_amount}
                                                decimalScale={2}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 24 }}>
                                            <FieldUpload
                                                form={form}
                                                folder={'admin-media'}
                                                label={"Refunded Slip"}
                                                name={'refunded_slip'}
                                                required={false}
                                                defaultValue={form.defaults.refunded_slip}
                                                accept={"image/*"}
                                                limit={1}
                                            />
                                        </Col>

                                    </>
                                }

                            </Row>

                        </Col>

                    </Row>
                </>
            }
        />

    ), [props, form.hash, process.loading, reverse.loading]);

}

export default OfficialReceiptsStageRefund;
