import React, { useCallback, useMemo } from 'react';
import { Col, DataTable, FieldDate, FieldNumber, FieldRelationship, FieldRepeater, FieldSelect, FieldTable, FieldText, FieldTextArea, FieldUpload, InputTableCell, InputTableHead, InputTableHeadCell, InputTableHeadRow, Line, MetaTable, Row, Space, useApp, useForm, useMountEffect, userHasCap, userHasRole } from '@dex/bubl-dash';
import Stage from '../../../elements/Stage/Stage';
import InvoicesApi from '../../Invoices/InvoicesApi';
import FieldBankAccounts from '../../../fields/FieldBankAccounts/FieldBankAccounts';


const InvoicesStagesPayment: React.FC<any> = (props: any) => {

    const { data, isReceipt } = props;

    const process = InvoicesApi.update(data.id, "stage_payment");

    const app = useApp();

    const form = useForm(data);

    const calculatePaid = useCallback((payments) => {

        let total = 0;

        payments.forEach((payment) => {

            total += parseFloat(payment.amount);

        });

        return total;

    }, []);

    const paid = calculatePaid(form.values.payments || []);
    const balance = data.total - paid;

    const isSuper = userHasRole("super");
    const isAdmin = userHasRole("admin");
    const isManager = userHasRole("manager");

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"payment_received"}
            heading={"Payments Received"}
            editable={data.payments?.length > 0 && (isManager || isAdmin || isSuper) && !isReceipt}
            editCaps={["stage_paymentInvoices"]}
            showForm={data.status === "due" || data.status === "partially_paid"}
            showMeta={data.status !== "due"}
            showSave={form.changed}
            passed={data.status === "paid"}
            renderMeta={() => (
                <>

                    <Space />

                    <DataTable
                        data={data.payments || []}
                        columns={[
                            {
                                key: "date",
                                label: "Date",
                                format: "date",
                                style: { maxWidth: 80 },
                            },
                            {
                                key: "mode",
                                label: "Mode",
                                format: "intl",
                                prefix: "merchants.payment_mode"
                            },
                            {
                                key: "account",
                                label: "Bank Account",
                            },
                            {
                                key: "amount",
                                label: "Amount",
                                format: "number",
                                prefix: "RM "
                            },
                            {
                                key: "reference_no",
                                label: "Reference No",
                            },
                            {
                                key: "notes",
                                label: "Notes",
                                format: "nl2br",
                                style: { maxWidth: 200 },
                                wrap: true
                            },
                            {
                                key: "photo",
                                label: "Photo",
                                format: "file",
                                elipsis: true,
                            },
                        ]}
                    >

                    </DataTable>

                    <Space />

                </>
            )}
            fields={
                <>
                    <FieldRepeater
                        form={form}
                        name={'payments'}
                        defaultValues={{ amount: balance }}
                        addButtonLabel={"Add Transaction"}
                    >

                        {(form.values.payments || []).map((row, n) => (

                            <Row edge gutter={8} key={form.key + "-" + n}>

                                <Col col={{ xs: 24, md: 16 }}>

                                    <Row gutter={8} edge>

                                        <Col col={{ xs: 24, sm: 12 }}>

                                            <FieldDate
                                                form={form}
                                                name={`payments[${n}].date`}
                                                defaultValue={row.date}
                                                label='Transaction Date'
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, sm: 12 }}>

                                            <FieldSelect
                                                form={form}
                                                name={`payments[${n}].mode`}
                                                defaultValue={row.mode}
                                                label='Payment Mode'
                                                options={[
                                                    { label: app.intl("merchants.payment_mode.cheque"), value: "cheque" },
                                                    { label: app.intl("merchants.payment_mode.instant_transfer"), value: "instant_transfer" },
                                                    { label: app.intl("merchants.payment_mode.interbank_giro"), value: "interbank_giro" },
                                                    { label: app.intl("merchants.payment_mode.others"), value: "others" },
                                                ]}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, sm: 12 }}>

                                            <FieldBankAccounts
                                                form={form}
                                                name={`payments[${n}].account`}
                                                defaultValue={row.account}
                                                label={"Bank Account"}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, sm: 12 }}>

                                            <FieldNumber
                                                form={form}
                                                name={`payments[${n}].amount`}
                                                defaultValue={row.amount}
                                                label='Amount'
                                                prefix={"RM "}
                                                decimalScale={2}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24 }}>

                                            <FieldText
                                                form={form}
                                                name={`payments[${n}].reference_no`}
                                                defaultValue={row.reference_no}
                                                label='Reference No'
                                            />

                                        </Col>

                                        <Col col={{ xs: 24 }}>

                                            <FieldTextArea
                                                form={form}
                                                name={`payments[${n}].notes`}
                                                defaultValue={row.notes}
                                                label='Notes'
                                            />

                                        </Col>


                                    </Row>

                                </Col>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldUpload
                                        form={form}
                                        name={`payments[${n}].photo`}
                                        defaultValue={row.photo}
                                        folder='finance-media'
                                        limit={3}
                                        label='Verification Photo'
                                    />

                                </Col>

                            </Row>

                        ))}

                    </FieldRepeater>

                    <Space />

                    {form.values.payments?.length > 0 &&
                        <MetaTable
                            data={{ balance: balance, total: data.total, paid: paid }}
                            columnA={[
                                {
                                    key: "total",
                                    label: "Invoice Total",
                                    format: "number",
                                    prefix: "RM "
                                }
                            ]}
                            columnB={[
                                {
                                    key: "paid",
                                    label: "Amount Paid",
                                    format: "number",
                                    prefix: "RM "
                                }
                            ]}
                            columnC={[
                                {
                                    key: "balance",
                                    label: "Balance Due",
                                    format: "number",
                                    prefix: "RM "
                                }
                            ]}
                        />
                    }

                </>
            }
        />

    ), [data, form.hash]);

}

export default InvoicesStagesPayment;
