import React, { useMemo, useCallback } from 'react';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import { useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import OfficialReceiptsApi from '../OfficialReceiptsApi';
import OfficialReceiptsCreateEditForm from './OfficialReceiptsCreateEditForm';

const OfficialReceiptsSingleEdit: React.FC<any> = (props: any) => {

    const app = useApp();

    const { data } = props;

    const form = useForm(data);

    const update = OfficialReceiptsApi.update(data.id, "update", {
        onComplete: (data) => {

            app.alert('Bank Account Saved', 'success');

        }
    });

    const handleSubmit = useCallback((values) => {

        update.run({ data: values });

    }, [props]);

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <OfficialReceiptsCreateEditForm
                    mode={"edit"}
                    form={form}
                    data={data}
                    handleSubmit={handleSubmit}
                    process={update}
                />

            </Col>

        </Row>

    ), [data, form.hash, update]);

}

export default OfficialReceiptsSingleEdit;
