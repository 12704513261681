import React from 'react';
import { get } from "@dex/bubl-helpers";

const AmountWithSST = (value, data, dash, column): any => {

    if (value == null || value === undefined) {
        return "-";
    }

    const sst = get(data, column.key + "_sst");

    return (
        <>
            {dash.formatValue("number", { prefix: "RM " }, value)}
            {sst && <> (SST: {sst})</>}
        </>
    );

}

export default AmountWithSST;
