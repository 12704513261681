import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import ServicesApi from '../ServicesApi';
import { FieldDate, FieldWrap, MetaTable, Space } from '@dex/bubl-dash';
import { date, dateDay } from '@dex/bubl-helpers';

const ServicesStageSelectWarrantyStart: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm({ warranty_start: data.merchant.warranty_end || "" });

    const process = ServicesApi.update(data.id, "stage_select_warranty_start");

    let warranty_end: any = new Date(form.values.warranty_start);

    warranty_end = date(warranty_end.setFullYear(warranty_end.getFullYear() + 1));

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            reverse={false}
            name={"select_warranty_start"}
            heading={"Select Warranty Period"}
            editCaps={["stage_select_warranty_startServices"]}
            editable={false}
            showForm={!data.warranty_start}
            showMeta={data.warranty_start}
            passed={data.warranty_start}
            showSave={form.values.warranty_start}
            columnA={[
                {
                    label: "Warranty Start Date",
                    key: "warranty_start",
                    format: "dateDay"
                },

            ]}
            columnB={[
                {
                    label: "Warranty End Date",
                    key: "warranty_end",
                    format: "dateDay"
                },
            ]}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldDate
                                form={form}
                                label={"Warranty Start Date"}
                                name={"warranty_start"}
                                required={true}
                                defaultValue={form.defaults.warranty_start}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldWrap
                                label='Warranty End Date'
                            >
                                <div>
                                    <Space height={12} />
                                    {warranty_end}
                                </div>
                            </FieldWrap>


                        </Col>
                    </Row>
                </>
            }
        />

    ), [props, form.hash, process.loading]);

}

export default ServicesStageSelectWarrantyStart;
