import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import { Alert, FieldCheckbox, FieldRadio, FieldRelationship, FieldSelect, FieldText, FieldTextArea, Line, Space } from '@dex/bubl-dash';
import ServicesApi from '../ServicesApi';
import { titleCase } from '@dex/bubl-helpers';

const ServiceStageTerminalSelected: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = ServicesApi.update(data.id, "stage_terminal_selected");
    const reverse = ServicesApi.update(data.id, "stage_reverse");

    let in_stock = ['store', 'store_used'];
    let priority = "Used Stock";

    if (data.type === "swap_plan") {
        data.device_type = data.engagement_plan.device_type;
    }

    const where: any = {
        type: data.device_type,
        status: 'in_stock',
        in_stock: { inq: in_stock }
    };

    if (data.merchant?.process === 'bir') {
        in_stock = ['store', 'acquirer_floating_unit'];
        where.in_stock = { inq: in_stock };
        priority = "Acquirer Floating Stock / New Stock";
    } else if (data.merchant?.process === 'mtp') {
        priority = "Merchant Transfered Stock";
    } else if (data.merchant?.business_model === 'maintenance') {
        delete (where.in_stock);
        where.status = { inq: ["deployed", "delivered"] };
        where.acquirer_id = data.merchant?.acquirer_id;
        priority = "Mass Rollout Acquirer Stock";
    }

    const swapOptions = (current) => {

        let options = [{ label: "No", value: "no" }];

        if (current) options.push({ label: "Yes", value: "yes" });
        else options.push({ label: "New", value: "new" });

        return options;

    }

    const editable = data.terminal_selected === "yes" && data.status === "perso";

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"terminal_selected"}
            heading={"Assign Asset"}
            editCaps={["stage_terminal_selectedServices"]}
            editable={editable}
            showForm={data.terminal_selected !== "yes"}
            showMeta={data.terminal_selected === "yes"}
            passed={data.terminal_selected === "yes"}
            showSave={form.changed}
            reverse={data.terminal_selected === "yes" ? null : reverse}
            last_stage={"update_details"}
            columnA={[
                {
                    label: "Swap Terminal",
                    key: "swap_terminal",
                    format: "titleCase"
                },

                data.swap_terminal === "yes" && {
                    label: "Current Serial Number",
                    key: "faulty_serial",
                },
                (data.swap_terminal === "new" || data.swap_terminal === "yes") ? {
                    label: "New Serial Number",
                    key: "terminal.serial",
                } : {
                    label: "Current Serial Number",
                    key: "faulty_serial",
                },

                data.swap_adapter === "yes" && {
                    label: "New Power Adapter Type",
                    key: "adapter_type",
                    format: "intl",
                    prefix: "service.adapter_type",
                },

                {
                    label: "Additional Notes",
                    key: "terminal_selected_notes",
                    format: "nl2br"
                },

            ].filter(Boolean)}
            columnB={[
                {
                    label: "Swap Sim Card",
                    key: "swap_sim_card",
                    format: "titleCase"
                },
                data.swap_sim_card === "yes" && {
                    label: "Current Sim Card",
                    key: "faulty_sim_card_serial"
                },
                (data.swap_sim_card === "new" || data.swap_sim_card === "yes") && {
                    label: "New Sim Card",
                    key: "sim_card_id",
                },
                (data.swap_sim_card === "new" || data.swap_sim_card === "yes") && {
                    label: "Sim Data Plan",
                    key: "sim_data_plan",
                },
                (data.swap_sim_card === "new" || data.swap_sim_card === "yes") && {
                    label: "Telco Network",
                    key: "telco_network",
                    format: "intl",
                    prefix: "service.telco_network",
                },
            ].filter(Boolean)}
            columnC={[
                {
                    label: "Swap Swivel",
                    key: "swap_swivel",
                    format: "titleCase"
                },

                data.swap_swivel === "yes" && {
                    label: "Current Swivel Serial",
                    key: "faulty_swivel_serial"
                },
                (data.swap_swivel === "new" || data.swap_swivel === "yes") && {
                    label: "New Swivel Serial",
                    key: "swivel_id",
                },

                {
                    label: "Swap Router",
                    key: "swap_router",
                    format: "titleCase"
                },

                data.swap_router === "yes" && {
                    label: "Current Router",
                    key: "faulty_router_serial"
                },
                (data.swap_router === "new" || data.swap_router === "yes") && {
                    label: "New Router",
                    key: "router_id",
                },
                {
                    label: "Swap Docking",
                    key: "swap_docking",
                    format: "titleCase"
                },

                data.swap_docking === "yes" && {
                    label: "Current Docking",
                    key: "faulty_docking_serial"
                },
                (data.swap_docking === "new" || data.swap_docking === "yes") && {
                    label: "New Docking",
                    key: "docking_id",
                }

            ].filter(Boolean)}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldRadio
                                form={form}
                                label={`Swap Terminal${(data.faulty_serial ? " - " + data.faulty_serial : "")}`}
                                name='swap_terminal'
                                defaultValue={form.defaults.swap_terminal}
                                required={true}
                                options={swapOptions(data.faulty_serial)}
                            />

                        </Col>

                        {(form.values.swap_terminal === "yes" || form.values.swap_terminal === "new") &&
                            <Col col={{ xs: 24, md: 8 }}>

                                <FieldRelationship
                                    form={form}
                                    label={"Serial Number (" + titleCase(data.device_type) + ") | (" + priority + ")"}
                                    name='terminal_id'
                                    defaultValue={form.defaults.terminal_id}
                                    endpoint={'Terminals'}
                                    required={true}
                                    preload={true}
                                    where={where}
                                    format={["serial", "status", "in_stock"]}
                                />

                            </Col>
                        }

                    </Row>

                    <Line top={"small"} bottom={"small"} />

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldRadio
                                form={form}
                                label={`Swap Sim Card${(data.faulty_sim_card_serial ? " - " + data.faulty_sim_card_serial : "")}`}
                                name='swap_sim_card'
                                defaultValue={form.defaults.swap_sim_card}
                                required={true}
                                options={swapOptions(data.faulty_sim_card_serial)}
                            />

                        </Col>

                        {(form.values.swap_sim_card === "yes" || form.values.swap_sim_card === "new") &&
                            <Col col={{ xs: 24, md: 8 }}>

                                <FieldRelationship
                                    form={form}
                                    label={"SIM Card No."}
                                    name='sim_card_id'
                                    required={true}
                                    defaultValue={form.defaults.sim_card_id}
                                    endpoint='Inventories'
                                    where={{ type: "sim", status: 'in_stock' }}
                                    preload={true}
                                />

                                <Row gutter={8} edge={true}>

                                    <Col col={{ xs: 12 }}>

                                        <FieldText
                                            form={form}
                                            label={"SIM Data Plan"}
                                            name='sim_data_plan'
                                            required={true}
                                            defaultValue={form.defaults.sim_data_plan}
                                        />

                                    </Col>
                                    <Col col={{ xs: 12 }}>

                                        <FieldSelect
                                            form={form}
                                            name={"telco_network"}
                                            label={"TELCO Network"}
                                            required={true}
                                            defaultValue={form.defaults.telco_network}
                                            options={[
                                                { value: "maxis", label: "Maxis" },
                                                { value: "digi", label: "Digi" },
                                                { value: "digi-ultra", label: "Digi-Ultra" },
                                                { value: "celcom", label: "Celcom" },
                                                { value: "myevo", label: "MyEvo" },
                                                { value: "myevo-multisim", label: "MyEvo - Multi Sim" },
                                                { value: "tns", label: "TNS" },
                                                { value: "umobile", label: "Umobile" },
                                            ]}
                                        />

                                    </Col>

                                </Row>

                            </Col>
                        }

                    </Row>

                    <Line top={"small"} bottom={"small"} />

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldRadio
                                form={form}
                                label={`Swap Swivel${(data.faulty_swivel_serial ? " - " + data.faulty_swivel_serial : "")}`}
                                name='swap_swivel'
                                defaultValue={form.defaults.swap_swivel}
                                required={true}
                                options={swapOptions(data.faulty_swivel_serial)}
                            />

                        </Col>

                        {(form.values.swap_swivel === "yes" || form.values.swap_swivel === "new") &&
                            <Col col={{ xs: 24, md: 8 }}>

                                <FieldRelationship
                                    form={form}
                                    label={"Swivel Serial No."}
                                    name='swivel_id'
                                    required={true}
                                    defaultValue={form.defaults.swivel_id}
                                    endpoint='Inventories'
                                    where={{ type: "swivel", status: 'in_stock' }}
                                    preload={true}
                                />

                            </Col>
                        }

                    </Row>

                    <Line top={"small"} bottom={"small"} />

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldRadio
                                form={form}
                                label={`Swap Router${(data.faulty_router_serial ? " - " + data.faulty_router_serial : "")}`}
                                name='swap_router'
                                defaultValue={form.defaults.swap_router}
                                required={true}
                                options={swapOptions(data.faulty_router_serial)}
                            />

                        </Col>

                        {(form.values.swap_router === "yes" || form.values.swap_router === "new") &&
                            <Col col={{ xs: 24, md: 8 }}>

                                <FieldRelationship
                                    form={form}
                                    label={"Router Serial No."}
                                    name='router_id'
                                    required={true}
                                    defaultValue={form.defaults.router_id}
                                    endpoint='Inventories'
                                    where={{ type: "router", status: 'in_stock' }}
                                    preload={true}
                                />

                            </Col>
                        }

                    </Row>

                    <Line top={"small"} bottom={"small"} />

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldRadio
                                form={form}
                                label={`Swap Docking${(data.faulty_docking_serial ? " - " + data.faulty_docking_serial : "")}`}
                                name='swap_docking'
                                defaultValue={form.defaults.swap_docking}
                                required={true}
                                options={swapOptions(data.faulty_docking_serial)}
                            />

                        </Col>

                        {(form.values.swap_docking === "yes" || form.values.swap_docking === "new") &&
                            <Col col={{ xs: 24, md: 8 }}>

                                <FieldRelationship
                                    form={form}
                                    label={"Docking Serial No."}
                                    name='docking_id'
                                    required={true}
                                    defaultValue={form.defaults.docking_id}
                                    endpoint='Inventories'
                                    where={{ type: "docking", status: 'in_stock' }}
                                    preload={true}
                                />

                            </Col>
                        }

                    </Row>

                    <Line top={"small"} bottom={"small"} />

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldRadio
                                form={form}
                                label={`Swap Power Adapter`}
                                name='swap_adapter'
                                defaultValue={form.defaults.swap_adapter}
                                required={true}
                                options={swapOptions(true)}
                            />

                        </Col>

                        {(form.values.swap_adapter === "yes" || form.values.swap_adapter === "new") &&
                            <Col col={{ xs: 24, md: 8 }}>

                                <FieldRadio
                                    button
                                    form={form}
                                    label={"Power Adapter Type"}
                                    name='adapter_type'
                                    required={true}
                                    defaultValue={form.defaults.adapter_type}
                                    options={[
                                        { label: "Original", value: "original" },
                                        { label: "Third Party", value: "third_party" },
                                    ]}
                                />

                            </Col>
                        }

                    </Row>

                    <Line top={"small"} bottom={"small"} />

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 16 }}>

                            <FieldTextArea
                                form={form}
                                label={"Additional Notes"}
                                name='terminal_selected_notes'
                                required={false}
                                defaultValue={form.defaults.terminal_selected_notes}
                            />

                        </Col>

                    </Row>
                    {data.terminal_selected === "yes" &&
                        <>
                            {data.terminal_id && data.terminal_id != form.values.terminal_id &&
                                <>
                                    <Space height={8} />
                                    <Alert
                                        type={"warning"}
                                        message={"Terminal Changed"}
                                        description={data.serial + " will be moved to bench repair"}
                                    />
                                </>
                            }

                            {data.sim_card_id && data.sim_card_id != form.values.sim_card_id &&
                                <>
                                    <Space height={8} />
                                    <Alert
                                        type={"warning"}
                                        message={"SIM Card Changed"}
                                        description={data.sim_card_serial + " will be moved to bench repair"}
                                    />
                                </>
                            }

                            {data.swivel_id && data.swivel_id != form.values.swivel_id &&
                                <>
                                    <Space height={8} />
                                    <Alert
                                        type={"warning"}
                                        message={"SWIVEL Changed"}
                                        description={data.swivel_serial + " will be moved to bench repair"}
                                    />
                                </>
                            }

                            {data.router_id && data.router_id != form.values.router_id &&
                                <>
                                    <Space height={8} />
                                    <Alert
                                        type={"warning"}
                                        message={"Router Changed"}
                                        description={data.router_serial + " will be moved to bench repair"}
                                    />
                                </>
                            }


                            {data.docking_id && data.docking_id != form.values.docking_id &&
                                <>
                                    <Space height={8} />
                                    <Alert
                                        type={"warning"}
                                        message={"Docking Changed"}
                                        description={data.docking_serial + " will be moved to bench repair."}
                                    />
                                </>
                            }

                        </>
                    }
                </>
            }

        />

    ), [props, form.hash, process.loading, reverse.loading]);

}

export default ServiceStageTerminalSelected;
