import IndexPage from '@dex/bubl-dash/src/lib/pages/IndexPage';
import ServicesCreateEditForm from "./Components/ServicesCreateEditForm";
import ServicesApi from "./ServicesApi";
import ServicesBatchUploadForm from './Components/ServicesBatchUploadForm';
import { get } from '@dex/bubl-helpers';

export default function (app, settings) {

    app.addRoute({
        name: settings.key + "New",
        zone: settings.zone,
        path: settings.key + "/create",
        caps: settings.createCaps,
        component: IndexPage,
        componentProps: {
            header: {
                getTitle: () => "Add New Service",
                getButtons: settings.indexButtons,
                getTabs: settings.indexTabs,
                getTabCounts: settings.indexTabCounts,
            },
            create: {
                form: ServicesCreateEditForm,
                getCreate: ServicesApi.create,
                getClone: ServicesApi.getOne,
                defaults: {},
                successMessage: "Service Saved",
                successRouteName: settings.key + "View",
                idKey: settings.idKey,
            },
        },
    });

    app.addRoute({
        name: settings.key + "BatchUpload",
        zone: settings.zone,
        path: settings.key + "/upload/:type",
        caps: settings.createCaps,
        component: IndexPage,
        componentProps: {
            header: {
                getTitle: () => "Batch Upload",
                getButtons: settings.indexButtons,
                getTabs: settings.indexTabs,
                getTabCounts: settings.indexTabCounts,
            },
            create: {
                form: ServicesBatchUploadForm,
                getCreate: ServicesApi.create,
                getClone: ServicesApi.getOne,
                handler: "batch_upload",
                handlerOptions: {
                    timeout: 360000
                },
                defaults: {},
                successCallback: (response, app, nav) => {
                    const type = get(nav, 'currentRoute.state.params.type', '');
                    const route = {
                        "type": "Navigation/NAVIGATE",
                        routeName: settings.key,
                        params: {
                            type: type,
                            batch: response.batch
                        }
                    };
                    app.navigate(nav, 'push', route);
                },
                idKey: settings.idKey,
            },
        },
    });

    // app.addRoute({
    //     name: settings.key + "YMBatchUpload",
    //     zone: settings.zone,
    //     path: settings.key + "/ym/create",
    //     caps: settings.createCaps,
    //     component: IndexPage,
    //     componentProps: {
    //         header: {
    //             getTitle: () => "Batch Upload Yearly Yearly",
    //             getButtons: settings.indexButtons,
    //             getTabs: settings.indexTabs,
    //             getTabCounts: settings.indexTabCounts,
    //         },
    //         create: {
    //             form: ServicesBatchUploadForm,
    //             getCreate: ServicesApi.create,
    //             getClone: ServicesApi.getOne,
    //             handler: "batch_upload",
    //             timeout: 60000,
    //             defaults: {},
    //             successCallback: (response, app, nav) => {
    //                 const route = { "type": "Navigation/NAVIGATE", routeName: settings.key, params: { type: "maintenance", batch: response.batch } };
    //                 app.navigate(nav, 'push', route);
    //             },
    //             idKey: settings.idKey,
    //         },
    //     },
    // });

}