import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import { FieldCheckbox, FieldNumber, FieldSelect, FieldTextArea, Seperator, useApp } from '@dex/bubl-dash';
import FieldAmount from '../../../fields/FieldAmount/FieldAmount';


const AddonsCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const app = useApp();

    return useMemo(() => (

        <Panel
            heading={"Addons Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 16 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldText
                                    form={form}
                                    label={"Addon Name"}
                                    name='addon_name'
                                    required={true}
                                    defaultValue={form.defaults.addon_name}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldText
                                    form={form}
                                    label={"SKU"}
                                    name='sku'
                                    required={false}
                                    defaultValue={form.defaults.sku}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldAmount
                                    form={form}
                                    label={"Selling Price"}
                                    name='selling_price'
                                    required={true}
                                    prefix={"RM "}
                                    defaultValue={form.defaults.selling_price}
                                    sst={true}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldNumber
                                    form={form}
                                    label={"Agent's Commission"}
                                    name='agents_commission'
                                    required={true}
                                    prefix={"RM "}
                                    defaultValue={form.defaults.agents_commission}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldCheckbox
                                    form={form}
                                    label={"Device Types"}
                                    name='device_types'
                                    required={true}
                                    defaultValue={form.defaults.device_types}
                                    options={[
                                        { value: "desktop", label: app.intl("terminals.type.desktop") },
                                        { value: "wireless", label: app.intl("terminals.type.wireless") },
                                        { value: "mpos", label: app.intl("terminals.type.mpos") },
                                        { value: "android", label: app.intl("terminals.type.android") }
                                    ]}
                                />

                            </Col>

                        </Row>

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldRadio
                            form={form}
                            label={"Status"}
                            name='status'
                            required={false}
                            defaultValue={form.defaults.status}
                            options={[
                                { label: "Active", value: "active" },
                                { label: "Disabled", value: "disabled" },
                            ]}
                        />

                    </Col>

                </Row>

                <Seperator
                    heading={"Additional Details"}
                    top={"auto"}
                    bottom={"auto"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 16 }}>

                        <FieldTextArea
                            form={form}
                            label={"Additional Notes"}
                            name='notes'
                            required={false}
                            defaultValue={form.defaults.notes}
                        />

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Save"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default AddonsCreateEditForm;
