import IndexTable from '@dex/bubl-dash/src/lib/components/IndexTable/IndexTable';
import React, { useMemo, useState } from 'react'
import InvoiceSettings from '../../Invoices/InvoicesSettings';
import { Status, useApp } from '@dex/bubl-dash';
import MerchantsApi from '../MerchantsApi';

const MerchantBilling: React.FC<any> = (props: any) => {

    const { data } = props;

    const [tableState, setTableState] = useState({ search: undefined, where: undefined, order: undefined, skip: undefined });

    const fetch = MerchantsApi.getBilling

    const indexColumns = (routeName, params, user) => {

        const columns = [
            {
                label: "Request ID",
                key: "this",
                format: "ModelLink",
            },
            {
                label: "Finance Type",
                key: "type",
                render: (value, row) => {
                    if (row.modelName === "Invoices") return "Invoice";
                    if (row.modelName === "ProformaInvoices") return "Proforma Invoice";
                    if (row.modelName === "OfficialReceipts") return "Official Receipt";
                }
            },

            {
                label: "Company Name",
                key: "company_name",
                format: "titleCase",
            },
            {
                label: "Description",
                key: "description",
                format: "nl2br",
            },
            {
                label: "Date Issued",
                key: "date_issued",
                format: "dateDay",
                alignEnd: true
            },
            {
                label: "Status",
                key: "status",
                render: (value, row) => {
                    return <Status status={row.status} />;
                }
            },
            {
                label: "Subtotal",
                key: "subtotal",
                format: "number",
                prefix: "RM "
            },

            {
                label: "Deposit",
                key: "deposit",
                format: "number",
                prefix: "RM "
            },

            {
                label: "Total",
                key: "total",
                format: "number",
                prefix: "RM "
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            }
        ];

        return columns;


    }

    const indexFetchParams = (routeName, params, users) => {

        let filters = InvoiceSettings.indexFetchParams(routeName, params, users);

        filters.limit = 1000;

        filters.where.debtor_id = data.id

        return filters;

    }

    const indexQuickSearch = (routeName, params, users) => {

        return ['id', 'status'];

    }
    const indexSearchFields = (routeName, params, users) => {

        return [
            {
                key: "id",
                label: "Request ID",
                type: "text",
            },
            {
                label: "Status",
                key: "status",
                type: "choice",
                options: [
                    { value: "due", label: "Due" },
                    { value: "partially_paid", label: "Partially Paid" },
                    { value: "paid", label: "Paid" },
                    { value: "void", label: "Void" },
                    { value: "cancelled", label: "Cancelled" },

                ]
            },
        ];

    }

    return useMemo(() => (

        <>

            <IndexTable
                title={"Billing"}
                hideTrashSwitch={true}
                getFetch={fetch}
                getColumns={indexColumns}
                preFetchFilters={indexFetchParams}
                routeParams={tableState}
                manageState={setTableState}
                getQuickSearch={indexQuickSearch}
                getSearchFields={indexSearchFields}
                tableProps={{
                    count: null,
                }}
            />

        </>

    ), [data, tableState]);
}

export default MerchantBilling